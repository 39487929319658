export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const AUTH_LOADING = 'AUTH_LOADING'
export const SET_USER = 'SET_USER'
export const AUTH_ERROR = 'AUTH_ERROR'

export const LOGOUT = 'LOGOUT'
export const LOGIN = 'LOGIN'
export const REGISTER = 'REGISTER'
export const GET_USER_LOADING = 'GET_USER_LOADING'
export const PRODUCTS_LOADING = 'PRODUCTS_LOADING'
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const ADD_TO_CART = 'ADD_TO_CART'
export const INCREMENT_QTY = 'INCREMENT_QTY'
export const DECREMENT_QTY = 'DECREMENT_QTY'
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM'
export const ADD_ORDER = 'ADD_ORDER'
export const ORDER_LOADING = 'ORDER_LOADING'
export const SHOP_ERROR = 'SHOP_ERROR'
export const GET_ORDERS = 'GET_ORDERS'
export const GET_POSTS = 'GET_POSTS'
